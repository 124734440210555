.chip{
	padding:4px 15px;
	border-radius:15px;
	line-height:100%;
	// font-size:12px;
  &.bg-azure{
    color:#fff;
  }
}

.list-chips{
  max-width: 200px;
  .chip{
    display: inline-block;
  }
}
