.card{
	background:$white;
	border: 1px solid $geyser;
	border-radius:pxtorem(2px);
	display: flex;
	position: relative;
	&__actions{
		position: absolute;
		top:pxtorem(16px);
		right:pxtorem(16px);
		display: flex;
		justify-content: space-between;
		align-items: center;
		a:hover{
			display: block;
			text-decoration: none;
		}
	}
	&__figure{
		.ico{
			font-size:pxtorem(36px);
		}
	}
	&__title{
		font-weight:500;
		color:$ebonyClay;
	}
  &__head{
    &--fill{
      background-color: #17a2b8;
      display: flex;
      justify-content: center;
      align-items: center;
      color:white;
      font-size: 1.5rem;
      font-weight: 700;
      min-height: 3rem;
      padding: 1rem;
    }
  }
	&--rounded{
		border-radius:pxtorem(3px);
		overflow: hidden;
  }
  &--hover{
    transition: box-shadow .3s ease-out, transform .3s ease-out;
    transition-delay: .1s;
    &:hover{
      cursor: pointer;
      box-shadow: rgba(45,45,45,0.05) 0px 1px 1px, rgba(49,49,49,0.05) 0px 2px 2px, rgba(42,42,42,0.05) 0px 4px 4px, rgba(32,32,32,0.05) 0px 8px 8px, rgba(49,49,49,0.05) 0px 16px 16px, rgba(35,35,35,0.05) 0px 32px 32px;
      transform: translate(0, -4px);
    }
  }
  &.is-selected{
    border-color:$tradewind;
    border-width: 2px;
  }
}
.card .badge {
    position: absolute;
		top:pxtorem(16px);
		left:0;
    z-index: 1;

    padding: 5px 15px;

    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background-color: #17a2b8;
  }
