.collapse{
	&__head{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding:15px;
		h2{
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #242D3E;
		}
		.ico{
			font-size:11px;
		}
	}
}
