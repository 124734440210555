.splash{
	&__index{
		font-weight: 300;
		font-size:pxtorem(24px);
		line-height: pxtorem(29px);
		color: $waterloo;
	}
	&__title{
		font-weight: 500;
		font-size: pxtorem(30px);
		line-height: pxtorem(36px);
		color:$azure;
	}
	p,
	&__text{
		font-size: pxtorem(16px);
		color:rgbs(#242D3E, .75);
	}
}
