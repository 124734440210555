@use "sass:math";

@mixin no-gutter {
	padding-left: 0;
	padding-right: 0;
}
@mixin img-resize {
	width: auto;
	height: 100%;
}
@mixin trim-text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin img-disabled {
	pointer-events: none;
	user-select: none;
}

// Triangle
@mixin triangle($direction, $size: 10px, $color: $black){
	content: '';
	display: block;
	position: absolute;
	height: 0;
	width: 0;
	@if ($direction == 'top'){
		border-bottom: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	}
	@else if ($direction == 'right'){
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}
	@else if ($direction == 'bottom'){
		border-top: $size solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	}
	@else if ($direction == 'left'){
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-right: $size solid $color;
	}
}
// .foo {
//   &:after {
//     @include triangle(bottom, 10px, blue);
//   }
// }

@function pxtorem($target, $context: $base_size) {
	@return math.div($target, $context) * 1rem;
}
// h1 {
//	font-size: px-to-rem(60px);
// }

@mixin color-variant($parent, $color, $ignore-warning: false) {
	#{$parent} {
		color: $color !important;
	}
	@include deprecate("The `color-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

@mixin bg-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    background-color: $color !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus() {
      background-color: darken($color, 10%) !important;
    }
  }
  @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
}

// Grid
@mixin grid($grid-type, $args, $row-gap, $column-gap: $row-gap) {
  @supports (display: grid) {
    display: grid;
    #{$grid-type}: #{$args};
    grid-gap: pxtorem($row-gap) pxtorem($column-gap);
  }
}

// Responsive tables
.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}



// Border
@mixin border($direction, $width, $style, $color) {
	@if ($direction == ''){
		border: $width $style $color;

	}@else {
		border-#{$direction}: $width $style $color;
	}
}
// .foo {
//   @include border('top', 1px, solid, #ccc);
// }
