.title{
	font-style: normal;
	font-weight: 300;
	font-size:pxtorem(26px);
	line-height:100%;
	display: flex;
	align-items: center;
	color:rgba($ebonyClay, .75);
}

.subtitle{
	font-style: normal;
	font-weight: 500;
	font-size: pxtorem(14px);
	line-height: pxtorem(17px);
	color:$ebonyClay;
}
