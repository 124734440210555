.thumb {
  border-radius: 15px;
  width: 100%;
  height: fit-content;
  background: #fff;
  color: #2c2f62;
  box-shadow: 0px 1px 3px rgba(34, 37, 51, 0.15);
  border: 1px solid rgba(45, 94, 166, 0.25);
  box-sizing: border-box;
  &__image {
    width: 100%;
    height: 200px;
    margin: 0;
    padding: 0;
    max-height: 100%;
    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      object-fit: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
  &__detail {
    padding: pxtorem(15px);
    flex: 1;
    flex-direction: column;
    display: flex;
  }
  &__price {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    color: $azure;
  }
}
