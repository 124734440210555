.ico {
	display: flex;
	font-size:pxtorem(16px);
	&__box{
		display: flex;
		justify-content: center;
		align-items: center;
		&--lg{
			width:pxtorem(140px);
			height:pxtorem(140px);
			.ico{
				font-size:pxtorem(60px);
			}
		}
		&--sm{
			width:pxtorem(80px);
			height:pxtorem(80px);
			.ico{
				font-size:pxtorem(40px);
			}
		}
		&--xs{
			width:pxtorem(60px);
			height:pxtorem(60px);
			.ico{
				font-size:pxtorem(36px);
			}
		}
	}
}
.ico--lg{
	font-size:pxtorem(88px);
}
.text-sm{
	font-size:pxtorem(12px);
}
.text-xs{
	font-size:pxtorem(10px);
}
.text-md{
	font-size:pxtorem(30px);
}
.text-lg{
	font-size:pxtorem(40px);

}
.text-decoration-underline{
	text-decoration: underline;
}
.img-full{
	width:100%;
	height:auto;
}
.img-card{
	max-width:200px;
	margin-top:20px;
}

.underline-none{
	text-decoration: none!important;
}

.overflow-hidden{
  overflow: hidden;
}
