.table{
	width:100%;
	border-collapse: collapse;
	// border-style: hidden;
	thead{
		border-bottom: 1px solid rgba(45, 94, 166, 0.25);
		tr{
			th{
				font-weight: 500;
				padding:pxtorem(10px);
				font-size:pxtorem(12px);
				line-height:pxtorem(15px);
				color:$ebonyClay;
				text-align: left;
			}
		}
	}
	tbody{
		tr{
			td{
				font-weight: normal;
				font-size:pxtorem(12px);
				line-height:pxtorem(15px);
				color:$ebonyClay;
				text-align: left;
				padding:pxtorem(12px) pxtorem(10px);
			}
		}
	}
	&--striped{
		tbody{
			tr:nth-child(even){
				td{
					background-color:rgba(206, 215, 224, 0.15);
				}
			}
		}
		&--odd{
			tbody{
				tr:nth-child(odd){
					td{
						background-color:rgba(206, 215, 224, 0.15);
					}
				}
			}
		}
	}
}
