.message{
	display: flex;
	&--sms{
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&__statement{
		text-align: center;
	}
}
