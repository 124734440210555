.box {
  border-radius: 15px;
  &--shadow {
    box-shadow: 0 2px 10px -8px hsla(0, 0%, 0%, 0.4);
  }
  &__header {
    padding: pxtorem(16px);
    border-bottom: solid 1px rgba(#222533, 0.2);
    .box__title {
      font-weight: 300;
      font-size: pxtorem(12px);
      line-height: pxtorem(16px);
    }
  }
  &__content {
    padding: pxtorem(16px);
  }
}
