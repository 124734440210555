.tabs{
	&__items{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom:solid 1px #E5E5E5;
	}
	&__link{
		display: block;
		line-height:100%;
		padding:pxtorem(20px);
		color:rgba($ebonyClay, .35);
		position: relative;
		overflow: hidden;
		&:hover{
			text-decoration: none;
			color:$azure;
			&::after{
				transform: translateX(0);
			}
		}
		&::after{
			content:'';
			width:100%;
			position: absolute;
			bottom:0;
			left:0;
			height:pxtorem(2px);
			background-color:$azure;
			transform: translateX(-100%);
			transition:transform .3s ease-in-out;
		}
		&.is-active{
			color:$azure;
			&::after{
				transform: translateX(0);
			}
		}
	}
	// &__content{

	// }
}
