/* .main {
  background-color: $ebonyClay;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  // width:pxtorem(80px);
  height: 100%;
  color: $white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
  position: relative;
  a {
    color: $white;
    .ico {
      font-size: pxtorem(26px);
    }
    &:hover {
      text-decoration: none;
    }
  }
  &__brand {
    display: flex;
    align-items: center;
    border-bottom: solid 1px #3a4251;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    b {
      margin-left: 1rem;
      display: none;
    }
    figure {
      margin: 0;
      width: pxtorem(40px);
      height: pxtorem(40px);
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
        user-select: none;
      }
    }
  }
  &__user {
    border-top: solid 1px #3a4251;
    margin-top: 2rem;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3a4251;
      padding: 1rem;
      b {
        margin-left: 1rem;
        display: none;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    transform: translateX(-100%);
  }
  &.expanded {
    width: initial;
    .toggle {
      img {
        transform: rotate(0deg);
      }
      // &:hover{
      //   img{
      //     transform: rotate(-180deg);
      //   }
      // }
    }
    .nav__link {
      justify-content: flex-start;
      b {
        display: block !important;
      }
    }
    .main__user,
    .main__brand {
      b {
        display: block;
      }
    }
  }
  .toggle {
    background-color: #ffffff;
    border-radius: 1.75rem;
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4rem;
    right: 0;
    border: none;
    line-height: 100%;
    transform: translateX(50%);
    z-index: 999;
    box-shadow: 0px 1px 3px rgba(34, 37, 51, 0.15);
    img {
      height: 0.75rem;
      width: auto;
      transition: transform 0.3s ease-in-out;
      transform: rotate(-180deg);
    }
    // &:hover{
    //   img{
    //     transform: rotate(0deg);
    //   }
    // }
  }
} */

.float-burger {
  position: fixed;
  bottom: pxtorem(15px);
  right: pxtorem(15px);
  background-color: $ebonyClay;
  width: pxtorem(52px);
  height: pxtorem(52px);
  border-radius: pxtorem(52px);
  box-shadow: 2px 0px 4px 0 rgba(#000, 0.2);
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  z-index: 998;
  .line {
    width: 25px;
    height: 3px;
    background-color: $white;
    position: absolute;
    display: block;
    &:last-child {
      transform: rotate(90deg);
    }
  }
  @include media-breakpoint-up(xl) {
    transform: translateX(150%);
  }
}

.view-container {
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  height: 100%;
  max-width: 70vw;
}

.svg-funnel-js {
  display: inline-block;
  position: relative;
}
.svg-funnel-js svg {
  display: block;
}
.svg-funnel-js .svg-funnel-js__labels {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__labels {
  flex-direction: column;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.svg-funnel-js {
  font-family: 'Open Sans', sans-serif;
}
.svg-funnel-js .svg-funnel-js__container {
  margin-top: 20px;
  width: 100%;
  height: 100%;
}
.svg-funnel-js .svg-funnel-js__labels {
  width: 100%;
  box-sizing: border-box;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label {
  flex: 1 1 0;
  position: relative;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
  font-size: 36px;
  color: #2d5ea6;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 6px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title {
  font-size: 12px;
  margin-right: 10px;
  color: #5b626e;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
  font-size: 16px;
  font-weight: bold;
  color: olivedrab;
  margin-bottom: 30px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  left: 0;
  padding: 8px 24px;
  box-sizing: border-box;
  background-color: rgba(8, 7, 48, 0.8);
  margin-top: 24px;
  opacity: 0;
  transition: opacity 0.1s ease;
  cursor: default;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__segment-percentages ul li {
  font-size: 13px;
  line-height: 16px;
  color: #ffb178;
  font-weight: bold;
  margin: 18px 0;
}
.svg-funnel-js
  .svg-funnel-js__labels
  .svg-funnel-js__label
  .label__segment-percentages
  ul
  li
  .percentage__list-label {
  color: #fff;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label:hover .label__segment-percentages {
  opacity: 1;
}
.svg-funnel-js:not(.svg-funnel-js--vertical) {
  padding-top: 64px;
  padding-bottom: 16px;
}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label {
  padding-left: 24px;
}
.svg-funnel-js:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
  border-left: 1px solid gainsboro;
}
.svg-funnel-js.svg-funnel-js--vertical {
  padding-left: 120px;
  padding-right: 16px;
}
.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label {
  padding-top: 24px;
}
.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label:not(:first-child) {
  border-top: 1px solid grey;
}
.svg-funnel-js.svg-funnel-js--vertical .svg-funnel-js__label .label__segment-percentages {
  margin-top: 0;
  margin-left: 106px;
  width: calc(100% - 106px);
}
.svg-funnel-js.svg-funnel-js--vertical
  .svg-funnel-js__label
  .label__segment-percentages
  .segment-percentage__list {
  display: flex;
  justify-content: space-around;
}
.svg-funnel-js .svg-funnel-js__subLabels {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  position: absolute;
  width: 100%;
  left: 0;
}
.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
  display: flex;
  font-size: 12px;
  color: #2d5ea6;
  line-height: 16px;
}
.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel:not(:first-child) {
  margin-left: 16px;
}
.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel .svg-funnel-js__subLabel--color {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 2px 8px 2px 0;
}

.container-funnel {
  width: fit-content;
  border-radius: 15px;
  background: #fff;
  color: #2c2f62;
  padding: 1.25rem 0 1.25rem;
  box-shadow: 0 2px 10px -8px hsla(0, 0%, 0%, 0.4);
}

.container-chart {
  width: fit-content;
  border-radius: 15px;
  background: #fff;
  color: #2c2f62;
  margin-top: 2rem;
  padding: 2rem 2rem 1.25rem;
  box-shadow: 0 2px 10px -8px hsla(0, 0%, 0%, 0.4);
}

.container-clients {
  width: 100%;
  border-radius: 15px;
  background: #fff;
  color: #2c2f62;
  padding: 1rem 1rem 1.25rem;
  box-shadow: 0 2px 10px -8px hsla(0, 0%, 0%, 0.4);
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 22px;
  margin-right: -50px;
  margin-top: 15px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari prior 9.0 */
  transform: rotate(45deg); /* Standard syntax */
}
