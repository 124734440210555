html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size:$dom-size;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
	margin:0;
	padding:0;
}

body {
	font-family:$font-base;
	font-size:$base-size;
	color:$shuttleGray;
	background-color:$aquaHaze;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin:0;
	padding:0;
}
main{
	// display: flex;
	height:100%;
	min-height:100vh;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
	// margin-bottom: .5rem;
}

p {
	margin-top: 0;
	// margin-bottom: 1rem;
}

ul, ol, dl {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

a,
button,
input,
select {
	transition-property: transform, background, background-color, border-color, color;
	transition-duration: $transition-duration;
}

textarea {
	resize: vertical;
}

a {
	color: white;
	text-decoration: none;
	&:hover{
		color:lighten($tradewind, $amount: 10);
		text-decoration: underline;
	}
	// &:focus {
	// 	outline:none;
	// }
}

img {
	vertical-align: middle;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

[role="button"] {
	cursor: pointer;
}

[hidden] {
	display: none !important;
}
#slick-areas{
	margin-bottom:0;
}

hr{
	height: 1px;
	background-color: #E5E5E5;
	border: none;
}

// .ngxp__container
.ngxp__container{
  background: #FFF;
  border: none!important;
  box-shadow: 0 2px 4px -2px rgb(0 0 0 / 75%)!important;
  color: $cello;
  padding: 0!important;
  overflow: hidden;
}
// .ngxp__arrow{}
// .ngxp__inner{}
.unit-popup{
  text-align: left;
  .card{
    border: none;
  }
}
