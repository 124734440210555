// Color system
$white:#FFFFFF;
$black:#000000;

$ebonyClay:#242D3E;
$shuttleGray:#5B626E;
$waterloo:#797D8F;
$azure:#2D5EA6;
$tradewind:#53B5B0;
$geyser:#CED7E0;
$aquaHaze:#F6F9FA;
$carnation:#F76664;
$texasRose:#FFBB4E;
$emerald:#52D071;
$lavenderBlue:#C9E0FF;
$cello:#3D505F;

$family-colors: () !default;
$family-colors: map-merge(
  (
		"azure": $azure,
		"tradewind": $tradewind,
		"geyser": $geyser,
		"ebonyClay-third":rgba($ebonyClay, .75),
		"ebony-clay":$ebonyClay,
		"waterloo":$waterloo,
		"aqua-haze":$aquaHaze,
		"tranparent":tranparent,
  ),
  $family-colors
);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
		"azure": $azure,
		"tradewind": $tradewind,
		"geyser": $geyser,
		"geyser-half": rgba($geyser, .5),
		"ebonyClay-third":rgba($ebonyClay, .75),
		"ebony-clay":$ebonyClay,
		"waterloo":$waterloo,
		"aqua-haze":$aquaHaze,
		"emerald":$emerald
  ),
  $theme-colors
);
// Spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    7: ($spacer * 4.5), // 72 pixeles
    10: ($spacer * 6.25) // 100 pixels
  ),
  $spacers
);

// Fonts
$dom-size:16px;
$base-size:0.875rem;
$font-base:'Inter', sans-serif;

// Common
$transition-duration: .3s !default;

