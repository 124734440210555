.pager{
	display: flex;
	justify-content: space-between;
	align-items: center;
	&__control{
		a{
			color:$shuttleGray;
		}
	}
}
