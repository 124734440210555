@font-face {
	font-family: "project-icons";
	src: url("../assets/fonts/project-icons.eot?bdde50532672b86378f15c22bfed6a45?#iefix") format("embedded-opentype"),
url("../assets/fonts/project-icons.woff2?bdde50532672b86378f15c22bfed6a45") format("woff2"),
url("../assets/fonts/project-icons.woff?bdde50532672b86378f15c22bfed6a45") format("woff"),
url("../assets/fonts/project-icons.ttf?bdde50532672b86378f15c22bfed6a45") format("truetype"),
url("../assets/fonts/project-icons.svg?bdde50532672b86378f15c22bfed6a45#project-icons") format("svg");
}

.ico {
	line-height: 1;
}

.ico:before {
	font-family: project-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ico-apps:before {
	content: "\f101";
}
.ico-bathroom:before {
	content: "\f102";
}
.ico-bedroom:before {
	content: "\f103";
}
.ico-broker:before {
	content: "\f104";
}
.ico-building:before {
	content: "\f105";
}
.ico-burger:before {
	content: "\f106";
}
.ico-calendar:before {
	content: "\f107";
}
.ico-celler:before {
	content: "\f108";
}
.ico-chart:before {
	content: "\f109";
}
.ico-check:before {
	content: "\f10a";
}
.ico-circle-plus:before {
	content: "\f10b";
}
.ico-close:before {
	content: "\f10c";
}
.ico-co-work:before {
	content: "\f10d";
}
.ico-cocktail:before {
	content: "\f10e";
}
.ico-comments:before {
	content: "\f10f";
}
.ico-cone-security:before {
	content: "\f110";
}
.ico-dashboard:before {
	content: "\f111";
}
.ico-dashed-check:before {
	content: "\f112";
}
.ico-dot:before {
	content: "\f113";
}
.ico-down:before {
	content: "\f114";
}
.ico-download:before {
	content: "\f115";
}
.ico-dragdrop:before {
	content: "\f116";
}
.ico-edit:before {
	content: "\f117";
}
.ico-email:before {
	content: "\f118";
}
.ico-empty:before {
	content: "\f119";
}
.ico-entertainment:before {
	content: "\f11a";
}
.ico-evaluate:before {
	content: "\f11b";
}
.ico-game-tower:before {
	content: "\f11c";
}
.ico-garden:before {
	content: "\f11d";
}
.ico-gourmet:before {
	content: "\f11e";
}
.ico-gym:before {
	content: "\f11f";
}
.ico-help:before {
	content: "\f120";
}
.ico-home:before {
	content: "\f121";
}
.ico-init:before {
	content: "\f122";
}
.ico-keypad:before {
	content: "\f123";
}
.ico-loader:before {
	content: "\f124";
}
.ico-next:before {
	content: "\f125";
}
.ico-options:before {
	content: "\f126";
}
.ico-parking:before {
	content: "\f127";
}
.ico-party:before {
	content: "\f128";
}
.ico-password:before {
	content: "\f129";
}
.ico-pencil:before {
	content: "\f12a";
}
.ico-pet-finger:before {
	content: "\f12b";
}
.ico-phone:before {
	content: "\f12c";
}
.ico-picker:before {
	content: "\f12d";
}
.ico-plus:before {
	content: "\f12e";
}
.ico-pool:before {
	content: "\f12f";
}
.ico-prev:before {
	content: "\f130";
}
.ico-process:before {
	content: "\f131";
}
.ico-recycle:before {
	content: "\f132";
}
.ico-roaster:before {
	content: "\f133";
}
.ico-roof-terraces:before {
	content: "\f134";
}
.ico-sale:before {
	content: "\f135";
}
.ico-schedule:before {
	content: "\f136";
}
.ico-swing:before {
	content: "\f137";
}
.ico-trash:before {
	content: "\f138";
}
.ico-up:before {
	content: "\f139";
}
.ico-update-password:before {
	content: "\f13a";
}
.ico-upload:before {
	content: "\f13b";
}
.ico-user:before {
	content: "\f13c";
}
.ico-view:before {
	content: "\f13d";
}
.ico-washed:before {
	content: "\f13e";
}
