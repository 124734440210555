// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

//TailwindCSS imports
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$simplyhaus-primary: mat.define-palette(mat.$indigo-palette);
$simplyhaus-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$simplyhaus-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$simplyhaus-theme: mat.define-light-theme(
  (
    color: (
      primary: $simplyhaus-primary,
      accent: $simplyhaus-accent,
      warn: $simplyhaus-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($simplyhaus-theme);

/* Vars */
@import 'theme/vars';

@import '@angular/cdk/overlay-prebuilt.css';

/* External*/
@import 'theme/override';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/utilities/text';
// @import "~bootstrap/scss/utilities/overflow";
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/background';
@import '~bootstrap/scss/utilities/position';
@import '~bootstrap/scss/utilities/visibility';
@import '~bootstrap/scss/utilities/borders';

/* functions */
@import 'theme/mixins';

/* Base */
@import 'theme/reset';

/* Components */
@import 'theme/components/alert';
//@import 'theme/components/buttons';
@import 'theme/components/messages';
@import 'theme/components/main';
@import 'theme/components/title';
@import 'theme/components/box';
@import 'theme/components/tabs';
@import 'theme/components/thumb';
@import 'theme/components/form';
@import 'theme/components/inputs';
@import 'theme/components/card';
@import 'theme/components/collapse';
@import 'theme/components/table';
@import 'theme/components/splash';
@import 'theme/components/pager';
@import 'theme/components/chip';
@import 'theme/components/project';
/* Utilities */
@import 'theme/icons';
@import 'theme/utilities';

@import '~@yaireo/tagify/src/tagify';
@import '~ng-snotify/styles/material';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Lato, 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Hellix';
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

@font-face {
  font-family: 'Gilmer-Bold';
  src: url('/assets/fonts/Gilmer-Bold.otf');
}

@font-face {
  font-family: 'Gilmer-Heavy';
  src: url('/assets/fonts/Gilmer-Heavy.otf');
}

@font-face {
  font-family: 'Gilmer-Light';
  src: url('/assets/fonts/Gilmer-Light.otf');
}

@font-face {
  font-family: 'Gilmer-Medium';
  src: url('/assets/fonts/Gilmer-Medium.otf');
}

@font-face {
  font-family: 'Gilmer-Outline';
  src: url('/assets/fonts/Gilmer-Outline.otf');
}

@font-face {
  font-family: 'Gilmer-Regular';
  src: url('/assets/fonts/Gilmer-Regular.otf');
}

@font-face {
  font-family: 'Hellix';
  src: url('/assets/fonts/hellix/HellixTRIAL-Medium.otf');
}

@font-face {
  font-family: 'Hellix-Regular';
  src: url('/assets/fonts/hellix/HellixTRIAL-Regular.otf');
}
table tr td a:hover {
  text-decoration: none;
}

.bg-gray-500 {
  background-color: #ced7e0;
  color: #333;
}
.tagify {
  --tag-pad: 0 0.5rem;
  min-height: pxtorem(36px);
}
.tagify__input:empty::before {
  line-height: inherit;
}

.tagify.form-control {
  height: unset;
}
:root {
  --tui-font-text: 'Hellix';
  --tui-font-heading: 'Hellix';
}
