.alert{
	border-radius:pxtorem(2px);
	padding:15px;
	&--info{
		background-color:$lavenderBlue;
		color:$cello;
	}
	&--warning{
		background-color:rgba($texasRose, .5);
		color:$cello;
	}
}
