.project{
	.hero{
		overflow: hidden;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		border-radius:2px 2px 0 0 ;
		padding: 15px;
		height:250px;
	}
	.profile{
		display: flex;
		color:$white;
		align-items: center;
		figure{
			margin:0;
			padding:0;
			background-color: $white;
			box-shadow: 0px 1px 3px rgba(34, 37, 51, 0.15);
			border-radius: 2px;
			border:solid 2px $white;
			margin-right:15px;
			img{
				object-fit: cover;
				width: 100%;
				height: 100%;
				pointer-events: none;
			}
		}
		h1{
			font-style: normal;
			font-weight: 600;
			font-size: 30px;
			line-height: 36px;
			display: flex;
			align-items: center;
			color: #FFFFFF;
			text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
		}
		&--small{
			figure{
				width:60px;
				height:60px;
			}
			h1{
				font-size:24px;
			}
		}
	}
}
